/*
* @subsection   RD Navbar
*
* @description  Describes style declarations for RD Navbar extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      2.0.0
*/
@import "rd-navbar_includes/_rd-navbar-mixins.scss";

$navbar-color: $grayscale-default;
$navbar-background: rgba($default-1, 0.75);
$navbar-shadow: $box-shadow-ambient, $box-shadow-key-light;
$navbar-width: 1170px;

$navbar-panel-color: $navbar-color;
$navbar-panel-background: $navbar-background;

$navbar-nav-min-width: 270px;
$navbar-nav-color: $navbar-color;
$navbar-nav-background: transparent;
$navbar-nav-hover-color: #FFF;
$navbar-nav-hover-background: $primary;
$navbar-nav-active-color: $navbar-nav-hover-color;
$navbar-nav-active-background: $navbar-nav-hover-background;

$navbar-dropdown-width: 121px;
$navbar-dropdown-background: $default-1;
$navbar-dropdown-item-color: $white;
$navbar-dropdown-item-background: transparent;
$navbar-dropdown-item-hover-color: #9dc4ba;
$navbar-dropdown-item-hover-background: transparent;
$navbar-dropdown-item-active-color: $navbar-dropdown-item-hover-color;
$navbar-dropdown-item-active-background: $navbar-dropdown-item-hover-background;

$navbar-megamenu-gutter: 30px;
$navbar-megamenu-background: $grayscale-darken;
$navbar-megamenu-item-color: $grayscale-default;
$navbar-megamenu-item-background: transparent;
$navbar-megamenu-item-hover-color: $primary;
$navbar-megamenu-item-hover-background: transparent;

$navbar-toggle-preset: "button-lines-arrow-2";
$navbar-search-toggle-preset: "search-preset-1";
$navbar-collapse-toggle-preset: "collapse-preset-1";

/*
* @subsection General Styles
*/

// RD Navbar Basics
%rd-navbar-transition {
  transition: .3s all cubic-bezier(0.785, 0.135, 0.150, 0.860);
}

%rd-navbar-hidden {
  display: none;
}

%rd-navbar-visible {
  display: block;
}

.rd-navbar--no-transition {
  &, * {
    transition: none !important;
  }
}

.rd-navbar-wrap {
  @extend %rd-navbar-transition;
}

.rd-navbar, .rd-navbar.rd-navbar--is-clone {
  @extend %rd-navbar-hidden;
}

.rd-navbar-fixed,
.rd-navbar-static,
.rd-navbar-fullwidth,
.rd-navbar-sidebar {
  @extend %rd-navbar-visible;
}

.rd-navbar-wrap, .rd-navbar, .rd-navbar-brand, .rd-navbar-slogan,
.rd-navbar-dropdown, .rd-navbar-megamenu, .rd-navbar-collapse-items,
.brand-name, .rd-navbar-nav, .rd-navbar-panel, .rd-navbar-search-form-input,
.rd-navbar-search-form-submit, .rd-navbar-search-toggle,
.rd-navbar-live-search-results, .rd-navbar-search-form {
  transition: .3s all ease;
}

// RD Navbar Collapse
.rd-navbar-collapse {
  &-toggle {
    @include make-toggle(
            $navbar-collapse-toggle-preset,
            $navbar-min-line-height,
            $navbar-min-font-size,
            $navbar-panel-color
    );
    display: none;
  }
}

// RD Navbar States
.rd-navbar--has-sidebar {
  body {
    padding-left: 270px;
  }
}

.rd-navbar--is-stuck {
  box-shadow: $navbar-shadow;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
  display: none;
}

.rd-navbar.rd-navbar--is-stuck.rd-navbar--loading {
  visibility: hidden;
  @include transform(translateY(-100%));
}

@import "rd-navbar_includes/_rd-navbar-components.scss";

/*
* @subsection   Hybrid  Styles
*/

//=========    Static & Fullwidth & Sidebar    ===========
.rd-navbar-static, .rd-navbar-sidebar, .rd-navbar-fullwidth {
  position: relative;
  z-index: 10;

  // RD Navbar Search
  .rd-navbar-search {
    &-form {
      &-input {
        display: block;
        input {
          display: block;
          width: 100%;
          padding: 4px 40px 7px 19px;
          height: 40px;
          font-size: 14px;
          line-height: 30px;
          color: #acacac;
          margin: 10px 0;
          border-bottom: 1px solid #61686d;
          border-left: 2px solid $primary;
        }
      }
      &-submit {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

//=============    Static & Fullwidth     ================
.rd-navbar-search-toggle {
  margin-right: 0px;
  margin-top: 0px;
  @media (min-width: $screen-md-min) {
    margin-right: 25px;
    margin-top: 2px;
  }
  span {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
    }
  }
}

.rd-navbar-static, .rd-navbar-fullwidth {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //background: $navbar-background;
    z-index: 1;
  }

  .rd-navbar-brand, .rd-navbar-nav > li > a, .rd-navbar-search-toggle {
    position: relative;
    z-index: 2;
  }

  .rd-navbar-nav-wrap {
    padding-right: 111px;
  }
  // RD Navbar Submenu
  .rd-navbar-nav {
    > li {
      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          opacity: 1;
          height: auto;
          display: block;
          visibility: visible;
          @include transform(translateY(0));

        }
        > .rd-navbar-submenu-toggle {
          &::before {
            color: $white;
          }
        }
      }
      > a {
        display: block;
        margin: 20px 10px 20px;
        font-size: 15px;
        line-height: 30px;
        color: $white;
        position: relative;
        &:before {
          background: $primary;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 41px;
          left: -9px;
          width: 141%;
          opacity: 0;
          transition: .3s all ease;
        }
      }

      &.focus > a, > a:hover, &.active > a {
        color: $primary;
        &:before {
          opacity: 1;
          top: 31px;
        }
      }
    }

    > .rd-navbar-submenu {
      > ul {
        box-shadow: $navbar-shadow;
      }

      li {
        font-size: 14px;
        line-height: 30px;
        &.opened {
          > .rd-navbar-dropdown,
          > .rd-navbar-megamenu {
            opacity: 1;
            height: auto;
            display: block;
            visibility: visible;
            @include transform(translateY(0));

          }
          > .rd-navbar-submenu-toggle {
            &::before {
              color: $white;
            }
          }
        }
      }

      > .rd-navbar-dropdown, > .rd-navbar-megamenu {
        opacity: 0;
        visibility: hidden;
        @include transform(translateY(30px));
      }

      &.focus {
        > .rd-navbar-dropdown, > .rd-navbar-megamenu {
          opacity: 1;
          visibility: visible;
          @include transform(translateY(0));
        }
      }

      .rd-navbar-submenu.focus > .rd-navbar-dropdown {
        display: block;
      }
    }
  }

  // RD Navbar Inner
  .rd-navbar-inner {
    position: relative;
    max-width: $navbar-width + 30px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
  }

  // RD Navbar Nav
  .rd-navbar-nav {
    > li > .rd-navbar-dropdown {
      display: block;
      position: absolute;
      padding-right: 3px;
      text-align: left;
      left: 50%;
      margin-left: -($navbar-dropdown-width / 2);
      width: $navbar-dropdown-width;
      background: $navbar-dropdown-background;
      z-index: 5;
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    background: $navbar-dropdown-background;
    padding: 10px 0 11px 0;

    .rd-navbar-dropdown {
      top: -9px;
      position: absolute;
      left: 100%;
      > li > a {
        text-transform: none;
        color: $white;
        font-size: 15px;
        &:hover {
          color: #16c495;
        }
      }
    }
    > li > a {
      display: block;
      margin: 0px 19px;
      color: $navbar-dropdown-item-color;
      background: $navbar-dropdown-item-background;
      font-size: 15px;
    }

    > li > a:hover {
      color: $navbar-dropdown-item-hover-color;
      background: $navbar-dropdown-item-hover-background;
    }

    > li.focus > a {
      color: $navbar-dropdown-item-active-color;
      background: $navbar-dropdown-item-active-background;
    }
  }

  // RD Navbar Megamenu
  .rd-navbar-megamenu {
    display: table;
    position: absolute;
    text-align: left;
    right: $navbar-megamenu-gutter/2;
    left: $navbar-megamenu-gutter/2;
    border-spacing: 31px 25px;
    table-layout: fixed;
    width: 100%;
    margin-top: 10px;
    max-width: $navbar-width;
    background: $navbar-megamenu-background;
    z-index: 4;

    > li {
      position: relative;
      display: table-cell;

      > p {
        font-weight: 400;
        text-transform: uppercase;
        color: $white;
      }

      > ul {
        padding: 8px 9px;

        li + li {
          margin-top: 5px;
        }

        a {
          display: inline-block;
          color: $navbar-megamenu-item-color;
          background: $navbar-megamenu-item-background;

          &:hover {
            color: $navbar-megamenu-item-hover-color;
            background: $navbar-megamenu-item-hover-background;
          }
        }
      }

      & + li {
        padding-left: 10px;
        &:before {
          content: '';
          position: absolute;
          top: $navbar-megamenu-gutter;
          bottom: $navbar-megamenu-gutter;
          width: 1px;
          right: 100%;
          margin-right: 17px;
          background: lighten($navbar-megamenu-background, 7.450980392156865%);
        }
      }
    }
  }

  // RD Navbar Clone
  &.rd-navbar--is-clone {
    display: none;
    @include transform(translateY(-510%));

    &.rd-navbar--is-stuck {
      @include transform(translateY(0));
      .rd-navbar-nav {
        .line-1::before {
          height: 43px;
        }
        > li {
          > a {
            padding: 0;
            margin: 20px 10px;
          }
        }
      }
      .rd-navbar-search-toggle {
        .line-2::before {
          height: 43px;
        }
      }
    }
  }
  // RD Navbar Search
  .rd-navbar-search {
    &-form {
      visibility: hidden;
      position: absolute;
      top: 100%;
      right: 0px;
      left: 0%;
      opacity: 0;
      padding: 10px 33px 22px 27px;
      z-index: 1;
      @media (min-width: $screen-lg-min) {
        right: 15px;
        left: 67.9%;
      }
     // @include transform(translateY(-100%));
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 50%;
        bottom: 0;
        width: 100%;
        @include transform(translate(-50%));
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }

      &:before {
        background: $default-1;
        //box-shadow: inset $box-shadow-key-light;
      }

      &-submit {
        right: 15px;
        margin-top: 5px;
        @include transform(scale(0.7));
      }

      &-input {
        position: relative;
      }
    }

    &-toggle {
      position: absolute;
      right: 15px;
      top: 50%;
      @include transform(translateY(-50%));
      color: $white;

      &:hover {
        color: $primary;
      }
    }

    &.active {
      .rd-navbar-search-form {
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0%));
      }
    }
  }

  // RD Navbar Live Search Results
  .rd-navbar-live-search-results {
    top: calc(100% + 92px);
    z-index: 0;
  }

  // RD Navbar Stuck
  &.rd-navbar--is-stuck,
  &.rd-navbar--is-clone {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;

    .rd-navbar-collapse {
      display: none;
    }

    .rd-navbar-nav {
      > li {
        > a {
          //padding: 23px 10px 17px;
        }
      }
    }
  }

  // RD Navbar States
  .rd-navbar--has-dropdown {
    position: relative;
  }

  &.rd-navbar--is-clone {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }
}

//=============      Fixed & Sidebar      ================
.rd-navbar-fixed, .rd-navbar-sidebar {
  // RD Navbar Collapse
  .rd-navbar-collapse-toggle {
    display: inline-block;
    z-index: 1;
  }
  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    display: block;
  }

  .rd-navbar-collapse-items {
    position: absolute;
    width: $navbar-nav-min-width - 10px;
    padding: 25px 15px;
    box-shadow: $navbar-shadow;
    color: $navbar-panel-color;
    background: $navbar-panel-background;
    font-size: 16px;
    line-height: 34px;

    li > * {
      vertical-align: middle;
    }

    li + li {
      margin-top: 10px;
    }

    .icon, a {
      display: inline-block;
      font-size: 16px;
      line-height: 30px;
    }

    .icon, a[class*="fa"]:before {
      display: inline-block;
      width: 30px;
      height: 30px;
      padding-right: 5px;
    }
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar {
  position: fixed;
  width: $navbar-nav-min-width;
  height: 100%;
  left: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  font-size: 16px;
  line-height: 34px;
  color: $navbar-color;
  background: $navbar-background;
  box-shadow: $navbar-shadow;
  z-index: 998;
  padding: 10px 4px;

  &:before, &:after {
    content: '';
    display: block;
    height: $navbar-min-height;
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: lighten($navbar-background, 10%);
    border: none;
    border-radius: 0;
    opacity: .2;
  }

  &::-webkit-scrollbar-track {
    background: $navbar-background;
    border: none;
    border-radius: 0;
  }
}

.rd-navbar-fixed .rd-navbar-nav,
.rd-navbar-sidebar .rd-navbar-nav {
  p {
    font-weight: 700;
    color: $grayscale-darken;
    padding: 5px 40px 5px 15px;

    > a {
      display: block;
      color: $grayscale-default;
      margin: -5px -40px -5px -15px;
      font-weight: 400;
      padding: 5px 40px 5px 15px;

      &:hover {
        color: $navbar-nav-hover-color;
        background: $navbar-nav-active-background;
        text-decoration: none;
      }
    }
  }

  li {
    position: relative;

    li > a {
      padding-left: 20px;
    }

    > a {
      position: relative;
      display: block;
      padding: 5px 45px 5px 15px;
      color: $white;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &.active > a {
      color: $white;
      background: $primary;
    }

    &.focus > a, > a:hover {
      color: $navbar-nav-hover-color;
      background: $navbar-nav-active-background;
    }

    & + li, > img + a, > a + a, > a + ul {
      margin-top: ($navbar-min-height - $navbar-min-line-height) / 2;
    }
  }

  // RD Navbar Dropdown
  .rd-navbar-dropdown {
    > li > a {
      padding-left: 20px;
    }

    ul > li > a {
      padding-left: 25px;
    }
  }

  // RD Navbar Submenu
  .rd-navbar-submenu {
    > .rd-navbar-megamenu,
    > .rd-navbar-dropdown {

    }

    &.focus {

    }
  }
}

.rd-navbar-fixed, .rd-navbar-sidebar {
  // RD Navbar Search
  .rd-navbar-search {
    &-form {
      &-submit {
        @include transform(scale(.7));
      }
    }
  }
}

@import "rd-navbar_includes/_rd-navbar-static.scss";
@import "rd-navbar_includes/_rd-navbar-fullwidth.scss";
@import "rd-navbar_includes/_rd-navbar-fixed.scss";
@import "rd-navbar_includes/_rd-navbar-sidebar.scss";

/*
* @subsection   Page boxed layout style redeclaration
*
* @description  Redefines navbar style inside boxed layout
*
* @see          ../modules/_page-layouts.scss
*/
html.boxed {
  &.rd-navbar--has-sidebar {
    body {
      padding-left: $navbar-nav-min-width + 30px;
      padding-right: 30px;
    }
  }

  .rd-navbar--is-clone {
    max-width: $layout-boxed-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.page-header2 {
  .rd-navbar {
    background: rgba(58, 66, 72, 1) none repeat scroll 0 0;
  }

  .rd-navbar-fixed .rd-navbar-panel::before {
    background: rgba(58, 66, 72, 1) none repeat scroll 0 0;
  }
  .rd-navbar-fixed .rd-navbar-nav, .rd-navbar-sidebar {
    background: rgba(58, 66, 72, 1) none repeat scroll 0 0;
  }
}

.rd-navbar-static .rd-navbar-search-form-submit, .rd-navbar-fullwidth .rd-navbar-search-form-submit {
  margin-top: 13px;
  right: 25px;
  &:hover, &:active {
    color: $primary;
  }
}

.rd-navbar-static, .rd-navbar-fullwidth {
  .rd-navbar--has-dropdown > .rd-navbar-submenu-toggle {
    cursor: pointer;
    position: relative;
    display: block;
    z-index: 999;
    &:before {
      color: $primary;
      content: "\f0d7";
      font: 400 12px/12px "FontAwesome";
      left: 50%;
      position: absolute;
      top: -39px;
      transform: translateX(-50%);
      transition: all 0.3s ease 0s;
    }

  }
  .rd-navbar-dropdown {
    .rd-navbar-submenu-toggle {
      &:before {
        color: $primary;
        content: "\f0da";
        font: 400 12px/12px "FontAwesome";
        right: 7px;
        left: auto;
        position: absolute;
        top: -19px;
        transition: all 0.3s ease 0s;
        z-index: 999;
      }
    }
  }

}

.rd-navbar-static .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle::after, .rd-navbar-fullwidth .rd-navbar-nav li.opened > .rd-navbar-submenu-toggle::after {
  color: $white;
}
.rd-navbar-static-linked {
  .rd-navbar-nav {
    > li {
      > a {
        margin: 46px 10px 44px;
      }
    }
  }
  .rd-navbar--is-stuck {
    .rd-navbar-nav {
      > li {
        > a {
          margin: 20px 0px;
        }
      }
    }
  }
}
.rd-navbar--is-stuck {
  .rd-navbar-submenu-toggle {
    //display: none !important;
  }
}

.rd-navbar--is-stuck {
  .rd-navbar-search-toggle {
    span {
      &:before {
        height: 43px;
      }
    }
  }
  .line-1 {
    &:before {
      height: 43px;
    }
  }
}

.rd-navbar-static,
.rd-navbar-fullwidth {
  .rd-navbar-search {
    .rd-navbar-live-search-results {
      opacity: 0;
      visibility: hidden;
    }
    &.active {
      .rd-navbar-live-search-results {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.rd-navbar-static.rd-navbar--is-stuck,
.rd-navbar-fullwidth.rd-navbar--is-stuck {
  .rd-navbar-search {
    .rd-navbar-live-search-results {
      opacity: 0;
      visibility: hidden;
    }
    &.active {
      .rd-navbar-live-search-results.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.rd-navbar--is-stuck{
  .rd-navbar-nav{
    >li{
      .rd-navbar-submenu-toggle::before {
        top: -19px;
      }
    }
  }
}
.rd-navbar-fullwidth-linked{
  .rd-navbar-nav{
    >li{
      .rd-navbar-submenu-toggle::before {
        top: -19px;
      }
    }
  }
}

