/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  h3{
    margin-top: 18px;
    @media (min-width: $screen-sm-min) {
      margin-top: 38px;
    }
  }
  h6{
    margin-top: 2px;
    @media (min-width: $screen-sm-min) {
      margin-top: 6px;
    }
  }
  p{
    margin-top: 10px;
    @media (min-width: $screen-sm-min) {
      margin-top: 20px;
    }
  }
}
.owl-carousel.owl-carousel-1 .owl-item {
  img{
    width: 100%;
  }
  h3{
    margin-top: 18px;
    @media (min-width: $screen-sm-min) {
      margin-top: 36px;
    }
  }
}


.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  font: 400 40px/80px 'FontAwesome';
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
  &:before{
    content: '\f144';
  }
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/*
 * Owl Navigation
 */

.owl-nav{

}
.owl-carousel-1{
  .owl-prev, .owl-next{
    top: 102%;
    @media (min-width: $screen-xs-min) {
      top: 102.9%;
    }
    @media (min-width: $screen-md-min) {
      top: 106.9%;
    }
  }
}

.owl-prev, .owl-next{
  cursor: pointer;
  position: absolute;
  top: 115.8%;
  @include transform(translateY(-50%));
  opacity: 1;
}

.owl-prev{
  left: 40%;
  @media (min-width: $screen-sm-min) {
    left: 47.6%;
  }
  &:before{
    transition: .3s all ease;
    content: '';
    background: url("../images/owl-prev.png") no-repeat;
    width: 13px;
    height: 27px;
    position: absolute;
  }
  &:hover{
    &:before{
      background-position: -13px 0;
    }
  }
}

.owl-next{
  right: 40%;
  @media (min-width: $screen-sm-min) {
    right: 48.8%;
  }
  &:before{
    transition: .3s all ease;
    content: '';
    background: rgba(0, 0, 0, 0) url("../images/owl-next.png") no-repeat scroll -13px 0;
    width: 13px;
    height: 27px;
    position: absolute;
  }
  &:hover{
    &:before{
      background-position: 0px 0;
    }
  }
}

/*
 * Owl Pagination
 */

.owl-dots{
  text-align: center;
  margin-top: 10px;
}

.owl-dot {
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #000;
  transition: box-shadow 0.3s ease;

  &:hover,
  &:focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.6);
  }

  &.active {
    box-shadow: inset 0 0 0 8px #000;
  }
}


.owl-carousel-2{
  width: 100%;
  @media (min-width: $screen-xs-min) {
    width: 370px;
  }
  @media (min-width: $screen-md-min) {
    width: 100%;
  }
  .owl-prev, .owl-next{
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    opacity: 1;

  }
  .owl-prev{
    left: 0;
    background: rgba(58,66,72 , 0.6);
    width: 63px;
    height: 100%;
    &:before {
      background: rgba(0, 0, 0, 0) url("../images/arrow-prev1.png") no-repeat scroll 0 0;
      content: "";
      height: 27px;
      position: absolute;
      transition: all 0.3s ease 0s;
      width: 13px;
      top: 50%;
      left: 50%;
      @include transform(translate(-50% , -50%));
    }
    opacity: 0;
    transition: .3s all ease;
    &:hover{
      opacity: 1;
    }

  }
  .owl-next{
    right: 0;
    background: rgba(58,66,72 , 0.6);
    width: 63px;
    height: 100%;
    &:before {
      background: rgba(0, 0, 0, 0) url("../images/arrow_next1.png") no-repeat scroll 0 0;
      content: "";
      height: 27px;
      position: absolute;
      transition: all 0.3s ease 0s;
      width: 13px;
      top: 50%;
      left: 50%;
      @include transform(translate(-50% , -50%));
    }
    opacity: 0;
    transition: .3s all ease;
    &:hover{
      opacity: 1;
    }
  }
}