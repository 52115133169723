/*
* @subsection   RD Mail Form
*
* @description  Describes style declarations for RD Mail Form extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.1.2
*/

$input-height: 47px;
$input-font-weight: inherit;
$input-font-size: 14px;
$input-line-height: 34px;

$input-offset-top: 30px;

$input-color: $default-3;
$input-background: none;

$input-border-radius: 0;

$input-border-color: #e5e5e5;
$input-border: 1px solid $input-border-color;

$input-padding: ($input-height - $input-line-height) / 2 18px;

@if ($input-border != none) {
  $input-padding: ($input-height - $input-line-height - (nth($input-border, 1) * 2)) / 2 18px;
}
$textarea-height: 127px;
$placeholder-clr: $input-color;
$placeholder-opacity: 1;
.rd-mailform {
  position: relative;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  .mfInfo, .mfProgress{
    border-radius: 0px;
  }
  fieldset {
    border: none;

    > .row + .row{
      margin-top: $input-offset-top;
    }
  }
}
.form-wrap {
  position: relative;

  &.has-error {
    .form-input {
      border-color: $form-feedback-invalid-color;
    }
  }

  &.has-focus {
    .form-input {
      border-color: $form-feedback-focus-color;
    }
  }
}

.rd-mailform label {
  //position: relative;
  display: block;
  width: 100%;
  //margin-top: $input-height / 2;
  

  &:first-child {
    margin-top: 0;
  }

  input, select {
    height: $input-height;
  }

  input, textarea {
    display: block;
    width: 100%;
    margin: 0;
    -webkit-appearance: none;
    font-size: $input-font-size;
    padding: $input-padding;
    line-height: $input-line-height;
    color: $input-color;
    background: $input-background;

    @if ($input-border != none) {
      border-bottom: $input-border;
      border-radius: $input-border-radius;
    }

    &:-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-webkit-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &::-moz-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }

    &:-ms-input-placeholder {
      color: $placeholder-clr;
      opacity: $placeholder-opacity;
    }
  }

  

  @media (min-width: $screen-sm-min) {
    margin-top: $input-offset-top;
  }
}
textarea {
  resize: vertical;
  overflow: auto;
  min-height: $input-height;
  height: $textarea-height;
  max-height: $textarea-height * 1.5;
}
.form-label {
  position: absolute;
  top: 23px;
  left: 0;
  right: 0;
  pointer-events: none;
  text-align: left;
  z-index: 9;
  transition: .25s;
  will-change: transform;
  transform: translateY(-50%);
  
  font-size: 14px;
  padding: 5.5px 18px;
  line-height: 34px;
  &.focus {
    opacity: 0;
  }
  [class*='bg-'] &{
    color: $white;
  }
  &.auto-fill {
    color: $black;
  }
  
  
}
.form-input {
  display: block;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  font-size: 14px;
  padding: 5.5px 18px;
  line-height: 34px;
  min-height: 46px;
  color: #656565;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  border-radius: 0;
  border-left: 2px solid $primary;
}

/*
* MF Controls
*/

.mfControls {
    button {
      width: 100%;
      font-size: 14px;
      color: $white;
      background: $primary;
      border-radius: 0;
      border: none;
      letter-spacing: 1.2px;
      margin-top: 31px;
      padding-bottom: 15px;
      padding-top: 15px;
      text-transform: uppercase;
      &:hover{
        color: $white ;
        background: $default-1;
      }
    }
}
.rd-mailform-1{
  .mfValidation{
    color: $white;
  }
  .mfInfo, .mfProgress{
    border-radius: 0px;
  }
  input{
    //color: $white !important;
    //border-color: rgba($white , 0.2) !important;
  }
  .mfControls {
    button {
      width: 100%;
      font-size: 14px;
      color: $white;
      background: $primary;
      border-radius: 0;
      border: none;
      letter-spacing: 1.2px;
      margin-top: 31px;
      padding-bottom: 15px;
      padding-top: 15px;
      &:hover{
        color: $white ;
        background: $default-1;
      }
    }
  }
  .mfPlaceHolder{
    color: $white;
  }
}
@import "mailform_modules/_mf-animation.scss";
@import "mailform_modules/_mf-placeholder.scss";
@import "mailform_modules/_mf-validation.scss";
@import "mailform_modules/_mf-submit.scss";
@import "mailform_modules/_mf-icon.scss";
@import "mailform_modules/_mf-select.scss";
@import "mailform_modules/_mf-datepicker.scss";



//
// Form validation
//
.form-validation {
  position: absolute;
  right: 8px;
  top: 0;
  z-index: 11;
  margin-top: 2px;
  font-size: 9px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  color: $form-feedback-invalid-color;
  transition: .3s;
}

.form-validation-left {
  .form-validation {
    top: 100%;
    right: auto;
    left: 0;
  }
}

.form-wrap + .form-wrap {
  margin-top: 30px;
}

.rd-search  {
  .form-wrap {
    .rd-navbar-search-form-submit {
      position: absolute !important;
      top: 0;
      right: 0;
    }
  }
}


//
// RD Search Results
//
.rd-search-results {
  .search-list {
    counter-reset: li;
    text-align: left;
    padding-left: 0;
    font-size: 18px;
    list-style-type: none;
    overflow: hidden;

    li div {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      white-space: nowrap;
    }

    li:only-child::before {
      display: none;
    }
  }

  .search-list-item {
    position: relative;
    padding-left: 40px;
    font-size: $font-size-base;
    color: $gray-500;

    &::before {
      content: counter(li, decimal-leading-zero) '.';
      counter-increment: li;
      position: absolute;
      left: 0;
      top: 0;
      font-size: 14px;
      line-height: inherit;
      font-weight: $headings-font-weight;
      color: $black;
    }
  }

  .search-list-item + .search-list-item {
    margin-top: 40px;
  }

  .search-title {
    font-size: 16px;
    color: $black;
  }

  .search {
    color: $white;
    padding: 0 .25em;
    background: $primary;
  }

  .match {
    padding: 5px;
    font-size: 12px;
    line-height: 1.7;
    letter-spacing: .1em;
    text-transform: uppercase;
    color: $black;

    em {
      margin: 0;
      font-style: normal;
    }
  }

  p * {
    margin: 0;
  }

  * + .match {
    margin-top: 10px;
  }

  @include media-breakpoint-up(md) {
    .search-title {
      font-size: 18px;
    }

    .search-list-item {
      &::before {
        top: -1px;
        font-size: 16px;
      }
    }

    * + p {
      margin-top: 12px;
    }
  }

  @include media-breakpoint-up(lg) {
    .search-list-item {
      padding-left: 40px;
    }
  }
}

* + .rd-search-results {
  margin-top: 40px;
}

/*
* Snackbars
*/

.snackbars {
  padding: 9px 16px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  text-align: left;
  background-color: $gray-800;
  border-radius: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

  font-size: 14px;

  .icon-xxs {
    font-size: 18px;
  }

  // padding on text
  p span:last-child {
    padding-left: 14px;
  }

  // snackbar icon on the left side
  &-left {
    display: inline-block;
    margin-bottom: 0;
  }

  // snackbar link or button on the right side
  &-right {
    display: inline-block;
    float: right;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 540px;
    padding: 12px 15px;
    font-size: 15px;
  }
}
// Form output
#form-output-global {
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 2000;
  visibility: hidden;
  transform: translate3d(-500px, 0, 0);
  transition: .3s all ease;

  &.active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  @include media-breakpoint-up(sm) {
    left: 30px;
  }
}

