/*
* Navbar components
*/

// RD Navbar
.rd-navbar {
  display: none;
  background: $navbar-background;
  //box-shadow: $box-shadow-key-light;
}

// RD Navbar Toggle
.rd-navbar-toggle {
  @include make-toggle(
          $navbar-toggle-preset,
          $navbar-min-line-height,
          $navbar-min-font-size,
          $grayscale-darken
  );
  display: none;
}

// RD Navbar Brand
.rd-navbar-brand {
  
  .brand-name {
    margin-top: 5px;
    color: $white;
    font-size: 24px;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }

  .brand-slogan {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
    color: $primary;
  }
}
// RD Navbar Nav
.rd-navbar-nav {
}

// RD Navbar Dropdown
.rd-navbar-dropdown {
  display: none;
}

// RD Navbar Search
.rd-navbar-search {
  &-toggle {
    display: inline-block;
    font: 400 18px/36px $fa;

    &, &:before, &:after {
      text-align: center;
      width: 36px;
      height: 36px;
    }

    &:before, &:after {
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      content: '\f002';
      transition: .3s all ease;
      @include transform(scale(1) rotate(0deg));
    }

    .rd-navbar:not(.rd-navbar-fixed) & {
      &:after {
        content: '\f00d';
        transition: .3s all ease;
        @include transform(scale(0) rotate(-90deg));
      }

      &.active {
        &:before {
          @include transform(scale(0) rotate(90deg));
        }

        &:after {
          @include transform(scale(1) rotate(0deg));
        }
      }
    }

    .rd-navbar-fixed &, .rd-navbar-fixed &:before {
      width: 48px;
      line-height: 48px;
    }
  }

  &-form-submit {
    @include make-toggle(
            $navbar-search-toggle-preset,
            $navbar-min-line-height,
            $navbar-min-font-size,
            $navbar-panel-color
    );
  }

  &-form {
    &-input {
      display: block;
    }
  }
}

// RD Navbar Live Search Results
.rd-navbar-live-search-results {
  position: absolute;
  left: 0%;
  right: 0px;
  padding: 16px 8px 8px;
  top: 100%;
  font-size: 16px;
  line-height: 34px;
  color: #333;
  background: #FFF;
  box-shadow: $navbar-shadow;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 998;
  @media (min-width: $screen-lg-min) {
    left: 67.9%;
    right: 15px;
  }


  .search-quick-result {
    padding-left: 8px;
    font-size: 14px;
    line-height: 30px;
    color: #757575;
  }

  .search_list {
    margin-top: 4px;
    font-size: 16px;
    line-height: 30px;

    li + li {
      margin-top: 2px;
    }

    .search_list li + li:last-child {
      margin-top: 8px;
      border-top: 1px solid #EBEBEB;
      padding-top: 7px;
    }
  }

  .search_link {
    display: block;
    padding: 8px;
    color: #757575;
    border-radius: 2px;

    &:hover {
      background: #F7F7F7;
    }

    p {
      margin-top: 0;
      font-size: 14px;
      display: none;
    }
  }

  .search_title {
    color: #212121;
    font-weight: 400;
  }

  .search_submit {
    display: block;
    text-align: center;
    padding: 8px;
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;

    &:hover {
      background: #F7F7F7;
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $screen-lg-min) {
    .search_link {
      p {
        display: block;
      }
    }
  }
}

.rd-navbar-live-search-results {
  //@include transform(translateY(-100%));
  @include transform(translateY(0%));
  &.active {
    @include transform(translateY(0));
    opacity: 1;
  }
}


.tablet {
  .rd-navbar-live-search-results {
    left: 40% !important;
    right: 6px !important;
  }
  .rd-navbar-search {
    &-form {
      right: 6px !important;
      left: 40% !important;
      margin-left: auto !important;
      input{
        border-bottom: 1px solid #61686d;
        border-left: 2px solid $primary;
        background: #3a4248;
      }
    }

  }

}

