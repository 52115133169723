/*
* @package      Style
* @description  This package holds all common style declarations
*               of the site
*/

@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";


@import "variables-custom";
@import "includes/_mixins.scss";

@import "includes/_normalize.scss";
@import "includes/_page-layouts.scss"; 
@import "includes/tm-grid/_tm-grid.scss";


@import "reset";

@import "components/_floating.scss";
@import "components/_text-alignment.scss";
@import "components/_text-styling.scss";
@import "components/_responsive-utilities";
@import "components/_animate.scss";
@import "components/offsets"; 

/*
* @section      Icon Fonts
* @description  This section holds all icon fonts stored in template
*/

@import "components/_font-awesome.scss";

/*
* @section      Components
* @description  This section holds all elements style declarations of template
*/

@import "components/_buttons.scss"; 
@import "components/_icons.scss";

/*
 * @subsection   Box
 */
@import "includes/tm-box/_boxes.scss";


/*
* @section      Extensions
* @description  This section holds some of style declarations for necessary
*               scripts
*/
@import "extensions/_rd-navbar.scss";
@import "extensions/_rd-mailform.scss";
@import "extensions/_rd-google-map.scss";
@import "extensions/_rd-parallax.scss";
@import "extensions/_ui-to-top.scss";
@import "extensions/_responsive-tabs.scss";
@import "extensions/_swiper.scss";
@import "extensions/_owl-carousel.scss";
@import "extensions/_magnific-popup.scss";
@import "extensions/_material-parallax.scss";
@import "extensions/lightgallery";

/*
* @section      Main Styles
* @description  This section holds template default elements style declarations
*/

html {
  font-size: 60px;

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    font-size: 70px;
  }

  @media (min-width: $screen-md-min) and (max-width: $screen-lg-min - 1) {
    font-size: 80px;
  }

  @media (min-width: $screen-lg-min) {
    font-size: 100px;
  }

  .old-ie {
    display: none;
  }

  &.lt-ie11 {
    .old-ie{
      display: block;
    }
  }
}

body {
  font-family: $default-font-family;
  font-weight: $default-font-weight;
  font-size: $default-font-size;
  line-height: $default-line-height;
  -webkit-text-size-adjust: none;
  color: $content-color;
  background: $content-bg;

  @media (min-width: $screen-sm-min) and (max-width: $screen-md-min - 1) {
    font-size: 14px;
  }

  @media (min-width: $screen-md-min) {
    font-size: $default-font-size;
    line-height: $default-line-height;
  }
}


/*
* @subsection   Typography
* @description  Describes default font settings for basic html elements and
*               css classes
*/

h1, h2, h3, h4, h5, h6,
.heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .heading-6 {
  display: block;
  color: $grayscale-darken;
  font-weight: 400;

  a:hover {
    color: $primary;
  }
}

h1, .heading-1 {
  font-size: 38px;
  line-height: 1.33;

  @media (min-width: $screen-sm-min) {
    font-size: 62px;
    line-height: 1.16;
  }

  > .heading-2{
    margin-bottom: rem(33px);
  }
}

h2, .heading-2 {
  font-size: 22px;
  line-height: 1.5;
  color: $default-1;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 300;
  mark{
    padding: 5px 60px;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 50px;
    line-height: 1.32;
  }
}

h3, .heading-3 {
  font-size: 20px;
  line-height: 1.5;
  color: $default-1;
  font-weight: bold;
  @media (min-width: $screen-sm-min) {
    font-size: 20px;
    line-height: 1.37;
  }
}

h4, .heading-4 {
  font-size: 26px;

  @media (min-width: $screen-sm-min) {
    font-size: 18px;
    line-height: 1.47;
  }
}

h5, .heading-5 {
  font-size: 22px;

  @media (min-width: $screen-sm-min) {
    font-size: 16px;
    line-height: 1.66;
  }
}

h6, .heading-6 {
  font-size: 14px;

  @media (min-width: $screen-sm-min) {
    font-size: 14px;
    line-height: 1.5;
  }
}

p, .list, .contact-info {
  font-weight: 400;

  a:hover {
    color: $primary;
  }
}

mark {
  display: inline-block;
  padding: 5px;
  color: #FFF;
  background: $primary;
}

em {
  color: $primary;
}

small, .small {
  display: block;
  font-size: 0.875em;
  line-height: 1.643em;
}

.big {
  font-size: 1.2em;
}

time {
  &:before{
    padding-right: 8px;
  }
}

hr{
  height: 1px;
  width: 100%;
  display: none;
  padding: 0;
  background: rgba($default, 0.04);
}


/*
* @section      Main Layout
* @description  This section holds class declarations for elements indent creation
*/

/*
* @subsection   Offsets
* @description  Describes vertical indentation between the elements
*/
.thumb1 + .thumb1{
  margin-top: 30px;
}
* + h1 {
}

* + h2 {

}

* + h3 {
}

* + h4 {

}

* + h5{

}

* + h6 {
}

* + p {

}

* + img {

}

* + .btn{

}

* + .link {

}

* + .list{

}

* + .index-list {

}

* + .marked-list {

}

* + .ordered-list {
}

* + .inline-list {

}

* + .contact-info {

}

* + .btn-group{
  margin-top: 45px;
}

* + .box,
* + .box-xs,
* + .box-sm,
* + .box-md,
* + .box-lg {

}

* + .row{

}

* + .container {

}

* + .container-fluid{
  margin-top: 66px;
}

* + .rd-mailform{

}

.offset {
  &-1 {
    margin-left: -23px;
  }

  &-2 {
    margin-right: -22px;
  }

  &-3 {
    margin-top: 5px;
  }
  &-4 {
    margin-top: 54px;
  }
  &-5 {
    margin-top: 60px;
  }
  &-6 {
    margin-top: 60px;
    @media (min-width: $screen-md-min) {
      margin-top: 0px;
    }
  }
  &-7 {
    margin-top: 40px;
  }
  &-8 {
    margin-top: 65px;
  }
  &-9 {
    margin-top: 60px;
    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
    }
  }
  &-10 {
    margin-top: 6px;
  }
  &-11 {
    margin-top: 11px;
  }
  &-12 {
    margin-top: 64px;
  }
  &-13 {
    margin-top: 57px;
  }
  &-14 {
    margin-top: 17px;
  }
  &-15 {
    margin-top: 13px;
  }
  &-16 {
    margin-top: 65px;
  }
  &-17 {
    margin-top: 36px;
  }
  &-18 {
    margin-top: 62px;
  }
  &-21 {
    margin-top: 62px;
    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
    }
  }
  &-19 {
    margin-top: 77px;
  }
  &-20 {
    margin-top: 39px;
  }
  &-22 {
    margin-top: 30px;
    @media (min-width: $screen-sm-min) {
      margin-top: 0px;
    }
  }
}

* + time{

}

p + p{

}

.box, .box-xs, .box-sm, .box-md, .box-lg {
  & + .box,
  & + .box-xs,
  & + .box-sm,
  & + .box-md,
  & + .box-lg {

  }
}

.flow-offset-1 {
  @include make-flow-offset(30px);
}

.container {
  .rd-navbar-search-results{
    .result-item + .result-item{
      margin-top: 30px;
    }
  }
}


/*
* @subsection   Postfix
* @description  Describes instant right margin classes
*/

.postfix-1 {

}


/*
* @subsection   Prefix
* @description  Describes instant left margin classes
*/

.preffix-1 {

}


/*
* @subsection   Insets
* @description  Describes instant padding classes
*/

.inset {
  &-1 {
    padding-left: 38px;
  }

  &-2 {

  }

  &-3 {

  }
}


/*
* @subsection   Well
* @description  Describes section inner indents
*/

.well-xs {
  padding-top: 50px;
  padding-bottom: 50px;
}

.well-sm {
  padding-top: 85px;
  padding-bottom: 159px;
  &-1 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 89px;
      padding-bottom: 174px;
    }
  }
  &-2 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 85px;
      padding-bottom: 96px;
    }
  }
  &-3 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 84px;
      padding-bottom: 107px;
    }
  }
  &-4 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 86px;
      padding-bottom: 98px;
    }
  }
  &-5 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 85px;
      padding-bottom: 93px;
    }
  }
}

.well-md {
  padding-top: rem(102px);
  padding-bottom: rem(100px);
  &-1 {
    padding-top: 80px;
    padding-bottom: 80px;
    @media (min-width: $screen-sm-min) {
      padding-top: 103px;
      padding-bottom: 112px;
    }
  }
  &-2 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.well-lg {
  padding-top: rem(120px);
  padding-bottom: rem(120px);
}

.well-xl {
  padding-top: rem(130px);
  padding-bottom: rem(121px);
  &-1 {
    padding-top: rem(80px);
    padding-bottom: rem(80px);
    @media (min-width: $screen-md-min) {
      padding-top: rem(187px);
      padding-bottom: rem(187px);
    }
  }
  &-2 {

  }
}

/*
* @section      Helpers
* @description  This section holds various helper classes for
*               conditional page styling
*/
/*
* @subsection   line-1,line-2 ....
*/
.contact_list {
  .cnt1{
    margin-top: -1px;
  }
  dt + dd{
    margin-top: 12px;
  }
  dd + dt{
    margin-top: 26px;
  }
  dt {
    font-weight: bold;
    font-size: 20px;
    color: $default-1;
    &:nth-child(5){
      margin-top: 24px;
    }
    &:nth-child(7){
      margin-top: 25px;
    }
  }
  dd {
    p{
      //line-height: 26px;
    }
    .aside {
      margin-right: 0px;
      @media (min-width: $screen-md-min) {
        margin-right: 15px;
      }
      span {
        font-size: 14px;
        line-height: 14px;
        color: $primary;
      }
    }
    .cnt {
      overflow: visible;
      a {
        color: $default-3;
        font-size: 14px;
        line-height: 14px;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.address{
  h2{
    text-transform: none;
  }
}
.letter-spacing-1{
  letter-spacing: 0.3px;
}
.letter-spacing-2{
  letter-spacing: 0.2px;
}
.line{
  &-1 {
    position: relative;
    &:before{
      content: "";
      width: 2px;
      height: 63px;
      background: rgba($white ,0.2);
      display: block;
      position: absolute;
      top: 50%;
      left: -41px;
      @include transform(translateY(-50%));
    }
  }
    &-2 {
      position: relative;
      &:before{
        content: "";
        width: 2px;
        height: 25px;
        background: rgba($white ,0.2);
        display: block;
        position: absolute;
        top: 50%;
        left: 0px;
        @include transform(translateY(-50%));
        @media (min-width: $screen-lg-min) {
          height: 63px;
        }
      }
  }
    &-3 {
      position: relative;
      padding-left: 36px;
      &:before{
        content: "";
        width: 2px;
        height: 75px;
        background: $default-2;
        display: block;
        position: absolute;
        top: 50%;
        left: -1px;
        margin-top: 21px;
        @include transform(translateY(-50%));
      }
    }
  &-4 {
    position: relative;
    &:before{
      content: "";
      width: 2px;
      height: 75px;
      background: $default-2;
      display: block;
      position: absolute;
      top: 50%;
      right: -31px;
      margin-top: 1px;
      @include transform(translateY(-50%));
    }
  }
  &-5 {
    position: relative;
    &:before{
      content: "";
      width: 2px;
      height: 75px;
      background: $default-2;
      display: block;
      position: absolute;
      top: 50%;
      left: -41px;
      margin-top: 1px;
      @include transform(translateY(-50%));
    }
  }
}
.round {
  border-radius: 50%;
}

/*
* @subsection   Colors
*/

html body .page { // Increases specificity to use with background helpers
  .text-primary {
    color: $primary;
  }
  .text-white {
    color: $white;
  }
  .text-default-2 {
    color: $default-2;
  }
  .text-default-3 {
    color: $default-3;
  }
}


/*
* @subsection   Backgrounds
*/
.bg-1{
  background: url("../images/page-1_img02.jpg") no-repeat scroll 50% 0 ;
  background-size: cover;
}
.bg-2{
  background: url("../images/page-1_img07.jpg") no-repeat scroll 50% 0 ;
  background-size: cover;
}
.bg-3{
  background: url("../images/page-2_img02.jpg") no-repeat scroll 50% 0 ;
  background-size: cover;
}
.bg-4{
  background: url("../images/page-2_img03.jpg") no-repeat scroll 50% 0 ;
  background-size: cover;
}
.bg-primary {
  background: $primary;

  &, #{headings()}{
    color: $white;
  }

  .icon-primary{
    color: $white;
    border-color: $white;
    background: transparent;
    box-shadow: $box-shadow-ambient;
  }
  
  p, .list, .terms-list{
    a:hover{
      color: $white;
      text-decoration: underline;
    }
  }
}


.box {
  @include clearfix();
  .aside{
    float: left;
    @media (max-width: 991px) {
      float: none;
    }
  }
  .cnt{
    overflow: hidden
  }
}

/*
* @subsection   Quote
*/
.quote{
p{
  cite{
    font-size: 20px;
  }
  span{
    width: 100%;
    height: 100%;
    line-height: 100px;
    font-size: 100px;
  }
}
}

/*
* @subsection   Pricing Box
*/

.pricing-box{

}

/*
* @subsection   Breadcrumbs
*/

.breadcrumbs{

}

/*
* @subsection   Post
*/

.post{

}

/*
* @subsection   List    //list-1
*/
.list-2 {

  margin-top: 65px;
  li + li{
    margin-top: 99px;
  }
  li{
    time + p{
      margin-left: 0px;
      @media (min-width: $screen-xs-min) {
        margin-left: 36px;
      }
    }
    p + p{
      margin-left: 0px;
      @media (min-width: $screen-xs-min) {
        margin-left: 31px;
      }
    }
    .btn-xs{
      margin-top: 13px;
    }
    h3{
      margin-top: 36px;
    }
    > div {
      margin-bottom: 10px;
      padding-bottom: 8px;
      margin-top: 5px;
      border-bottom: 1px solid $default-5;
      @media (min-width: $screen-md-min) {
        margin-top: 7px;
      }
      time , p{
        display: block;
        position: relative;
        @media (min-width: $screen-xs-min) {
          display: inline-block;
        }
      }
      a {
        padding-left: 25px;
        color: $primary;
        &:hover {
          color: $default-1;
        }
      }
      p span , a{
        font-size: 14px;
        line-height: 24px;
        color: $primary;
      }
      span{
        position: absolute;
        color: $primary;
        top: 50%;
        @include transform(translateY(-50%));
      }
      p{
        //margin-left: 0px;
        line-height: 24px;

      }

    }
  }
}
.list {
  &-1 {
    margin-right: 0px;
    margin-left: 40px;
    @media (min-width: $screen-md-min) {
      margin-right: 33px;
      margin-left: 0px;
    }
    li + li {
      margin-left: 20px;
      @media (min-width: $screen-sm-min) {
        margin-left: 45px;
      }
    }
    li {
      &:nth-child(3){
        margin-left: 20px;
        @media (min-width: $screen-sm-min) {
          margin-left: 56px;
        }
      }
      display: inline-block;
      a {
        color: $default-3;
        font-size: 15px;
        line-height: 26px;
        &:hover , &:active{
          color: $primary;
        }
      }
    }
  }

}

/*
* @subsection   Inline List
*/

.inline-list {
  > li {
    display: inline-block;
    a {
      color: $white;
      opacity: 0.5;
      transition: .3s all ease;
      padding: 0 4px;
      &:before{
        padding: 0 4px;
      }
      &:hover {
        opacity: 1;
      }
    }
    margin-top: 0;
    padding: 0 10px;
  }
}


/*
* @subsection   Marked List
*/

.marked-list {
  text-align: left;

  > li {
    position: relative;
    padding-left: 30px;

    &:before {
      content: "\f105";
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 400;
      font-size: 1em;
      font-family: $fa;
      color: $primary;
    }

    & + li {
      margin-top: 10px;
    }

    > a:hover {
      color: $primary;
    }
  }
}

/*
* @subsection   Index List
*/

.index-list {
  counter-reset: li;

  > li {
    > .index-list__counter{
      &:before {
        content: counter(li, decimal-leading-zero) '.';
        counter-increment: li;
      }
    }
  }

  // Sets offset between list rows
  @include make-flow-offset(30px);
}


/*
* @subsection   Ordered List
*/

.ordered-list {
  > li {
    padding-left: 20px;
    list-style-type: decimal;
  }
}


/*
* @subsection   Terms  List
*/
.terms-list {
  margin-top: 65px;
  > dt + dd {
    margin-top: 11px;
  }
  > dd + dt {
    margin-top: 25px;
  }
  dt{
    font-size: 20px;
    color: $default-1;
    font-weight: bold;
    line-height: 28px;
  }
  dd{
    line-height: 28px;
    a{
      display: inline-block;
      color: $primary;
      margin-top: 28px;
      &:hover{
        color: $default-1;
      }
    }
  }
}
/*
* @subsection   Contact Info
*/

.contact-info {
  p {
  }

  dl {
    dt, dd {
      display: inline-block;
    }

    dt:after {
      content: ':';
    }

    dt {
      min-width: 80px;
    }
  }
}


/*
* @subsection   Counter
*/

.counter{

}


/*
* @subsection   Thumb
*/
.thumb {
  position: relative;
  display: block;
  overflow: hidden;
  img {
    width: 100%;
  }
  a > img {
    width: 100%;
  }
  &__titel{
    position: absolute;
    top: 52%;
    left: 50%;
    opacity: 0;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    transform: translateX(-50%);
  }
  &__overlay1{
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba($primary, .7);
    opacity: 1;
    text-align: center;
    transition: .3s;
    > *{
      vertical-align: middle;
      @include transform(translateY(30px));
      transition: .3s all ease;
    }
    span{
      display: inline-block;
      position: absolute;
      z-index: 2;
      left: 50%;
      font-weight: bold;
      top: 50%;
      font-size: 20px;
      color: $white;
      @include transform (translate(-50%,-50%));
    }
  }
  &__overlay{
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    right: 10px;
    background: rgba($default-1, .7);
    opacity: 0;
    text-align: center;
    transition: .3s;
    > *{
      vertical-align: middle;
      @include transform(translateY(30px));
      transition: .3s all ease;
    }
    &:before{
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      @include transform (translate(-50%,-50%));
      background: url("../images/thumb.png") no-repeat;
      width: 32px;
      height: 32px;
    }
  }
  &__overlay{
    &:after{
      content: '';
      position: absolute;
      top: 2%;
      left: 3%;
      right: 3%;
      bottom: 3%;
    }
    &:before{
      top: 50%;
    }
  }
  &:hover {
    .thumb__overlay {
      opacity: 1;
      > *{
        @include transform(translateY(0));
      }
    }
    .thumb__overlay1 {
      opacity: 0;
    }
    .thumb__titel{
      opacity: 1;
    }
  }
}


/*
* @subsection   Link
*/

.link {
  display: inline-block;
}


/*
* @subsection   Video
*/

.video {
  position: relative;
  padding-bottom: percentage(340 / 570); // percentage( Height / Width ) on PSD

  > iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}


/*
* @section      Page Header
* @description  This section holds specific style redeclarations for some
*               of common elements in page header
*/
.page-header {
  position: relative;
}

/*
* @section      Page Content
* @description  This section holds specific style redeclarations for some
*               of common elements in page content
*/

.page-content {
  .desktop & {
    min-height: 100vh;
  }
}

/*
* @section      Page Footer
* @description  This section holds specific style redeclarations for some
*               of common elements in page footer
*/

.page-footer {
  padding-top: 89px;
  padding-bottom: 31px;
  background: transparent url("../images/footer.jpg") no-repeat scroll 50% 0 ;
  background-size: cover;
  
  .brand-name {
    letter-spacing: -0.5px;
    font-size: 40px;
    line-height: 1em;
    @media (min-width: $screen-sm-min) {
      font-size: 56.98px;
    }
  }
  .brand-slogan{
    font-size: 16px;
    letter-spacing: 0.4px;
    @media (min-width: $screen-sm-min) {
      font-size: 28.49px;
    }
  }
}
.copyright {
  font-size: 15px;
  color: $default-4;
  margin-top: 9px;
  a{
    &:hover{
      color: $primary;
    }
  }
}

.result-item p{
  word-wrap: break-word;
}
.search_error {
  overflow: hidden;
  text-overflow: ellipsis;
}