/*
* @subsection   Text Styling
*/

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-strike {
  text-decoration: line-through;
}

.text-thin {
  font-weight: 100;
}

.text-light {
  font-weight: 300;
}

.text-regular{
  font-weight: 400;
}

.text-medium{
  font-weight: 500;
}

.text-sbold{
  font-weight: 600;
}

.text-bold, strong {
  font-weight: 700;
}

.text-ubold {
  font-weight: 900;
}
.text-uppercase{
  text-transform: uppercase;
}
a:hover {
  text-decoration: none;
}