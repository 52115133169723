/*
* @subsection   Buttons
*/

$btn-xs-height: 40px;
$btn-xs-size: 14px;

$btn-sm-height: $btn-xs-height * 1.25;
$btn-sm-size: $btn-xs-size * 1.13;

$btn-md-height: $btn-sm-height * 1.2;
$btn-md-size: $btn-sm-size * 1.12;

$btn-lg-height: $btn-md-height * 1.33;
$btn-lg-size: $btn-md-size;

$btn-xl-height: $btn-lg-height * 1.125;
$btn-xl-size: $btn-lg-size * 1.26;

$btn-group-offset: 30px;

// Button General Styles
.btn {
  display: inline-block;
  text-align: center;
  line-height: 1.2;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  .icon{
    float: left;
    vertical-align: middle;
  }
  &:focus {
    box-shadow: none;
  }
}

// Button Layout Styles
.btn-xs {
  font-size: ceil($btn-xs-size);

  .icon{
    font-size: $btn-md-size * 1.33;
    padding-right: 10px;
    line-height: (1em / 1.33);
  }
}

.btn-sm {
  border-width: 2px;
  padding: ceil(($btn-sm-height - ($btn-sm-size * 1.2) - 4) / 2);
  font-size: ceil($btn-sm-size);

  .icon{
    font-size: $btn-md-size * 1.29;
    padding-right: 10px;
    line-height: (1em / 1.29);
  }
}

.btn-md {
  border-width: 2px;
  padding: ceil(($btn-md-height - ($btn-md-size * 1.2) - 4) / 2) 35px;
  font-size: ceil($btn-md-size);

  .icon{
    font-size: $btn-md-size * 1.42;
    padding-right: 10px;
    line-height: (1em / 1.42);
  }
}

.btn-lg {
  border-width: 2px;
  padding: ceil(($btn-lg-height - ($btn-lg-size * 1.2) - 4) / 2) 40px;
  font-size: ceil($btn-lg-size);

  .icon{
    font-size: $btn-lg-size * 2.47;
    padding-right: 20px;
    line-height: (1em / 2.47);
  }
}

.btn-xl {
  border-width: 2px;
  padding: ceil(($btn-xl-height - ($btn-xl-size * 1.2)) / 2);
  font-size: ceil($btn-xl-size);

  .icon{
    font-size: $btn-xl-size * 1.875;
    padding-right: 20px;
    line-height: (1em / 1.875);
  }
}

// Button Appearance Styles
.btn-default {
  color: $grayscale-darken;
  background: transparent;
  border-color: $grayscale-lighten;

  &:hover {
    background: $grayscale-lighten;
  }

  &:active {
    background: darken($grayscale-lighten, 5%);
    border-color: darken($grayscale-lighten, 5%);
  }
}

.btn-primary {
  display: inline-block;
  color: $primary;
  position: relative;
  background: transparent; 
  span{
    background: transparent url("../images/arrow1.png") no-repeat scroll -4px 0;
    display: inline-block;
    height: 9px;
    position: absolute;
    transition: all 0.3s ease 0s;
    width: 4px;
    top: 50%;
    right: -11px;
    @include transform(translateY(-50%));
  }
  &:hover, &:active{
    color: $default-1;
    background: transparent;
    box-shadow: none;
    span{
      background-position: 0px 0;
    }
  }
}





// Button Group Styles
.btn-group {
  @include transform(translateY(-$btn-group-offset));
  margin-bottom: -$btn-group-offset;
  margin-left: -$btn-group-offset;

  .btn {
    margin-top: $btn-group-offset;
    margin-left: $btn-group-offset;
  }
}