/*
* @subsection   Visibility System
*/

@include make-visible(block);
@include make-visible(inline-block);
@include make-visible(inline);
@include make-hidden();

@each $resolution, $alias in ($screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg){
  @media (min-width: $resolution) {
    @include make-visible(block, $alias);
    @include make-visible(inline-block, $alias);
    @include make-visible(inline, $alias);
    @include make-hidden($alias);
  }
}