/*
* Static Layout
*/

$navbar-static-nav-indent: 2px;

$navbar-static-nav-color: $navbar-nav-color;
$navbar-static-nav-background: $navbar-nav-background;
$navbar-static-nav-hover-color: $navbar-nav-hover-color;
$navbar-static-nav-hover-background: $navbar-nav-hover-background;
$navbar-static-nav-active-color: $navbar-nav-active-color;
$navbar-static-nav-active-background: $navbar-nav-active-background;

.rd-navbar-static-linked{
  .page-header1{


  .rd-navbar-wrap{
    bottom: 3.8%;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 999;
  }
  }
}

.rd-navbar-fullwidth-linked{
  .page-header1{
  .rd-navbar-wrap{
    left: 0;
    position: absolute;
    right: 0;
    top: 0%;
    z-index: 999;
  }
  }
}
.rd-navbar-static {
  display: block;

  .rd-navbar-panel, .rd-navbar-nav-wrap {
    display: table-cell;
    vertical-align: middle;
  }

  .rd-navbar-panel {
    text-align: left;
    min-width: 300px;
  }
  .rd-navbar-nav-wrap {
    width: 100%;
    text-align: right;
  }

  .rd-navbar-nav{
    margin-left: -$navbar-static-nav-indent;

    > li {
      display: inline-block;
      margin-left: 35px;
    }
  }

  // RD Navbar States
  .rd-navbar-static--visible{
    display: block;
  }

  .rd-navbar-static--hidden{
    display: none;
  }
}

