//==========================================================
//                        MIXINS
//==========================================================

// Note: Some of described mixins in this file requires the ie conditional comments
//
// For IE < 9
// <!--[if lt IE 9]>
//    <html class='lt-ie9'>
// <![endif]-->
//

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin clip($x1, $x2, $y1, $y2) {
  clip: rect($y1, $x2, $y2, $x1);
}

// Opacity
@mixin opacity($opacity) {
  opacity: $opacity;

  .lt-ie9 & {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity})";
    filter: alpha(opacity=$opacity*100);
  }
}

// Background-rgba
@mixin background($rgb, $opacity) {
  background: rgba($rgb, $opacity);

  .lt-ie9 & {
    background: none;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($rgb, $opacity))}', endColorstr='#{ie-hex-str(rgba($rgb, $opacity))}')";
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str(rgba($rgb, $opacity))}', endColorstr='#{ie-hex-str(rgba($rgb, $opacity))}');
    zoom: 1;
  }
}

// Animation
@mixin animation($anim) {
  -webkit-animation: $anim;
  animation: $anim;
}

// Flexbox Containers

@mixin flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

//----------------------------------

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

//----------------------------------------------------------------------

// Flexbox Direction
// Values: row | row-reverse | column | column-reverse
// Default: row

@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -moz-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

//----------------------------------------------------------------------

// Flexbox Wrap
// Values: nowrap | wrap | wrap-reverse
// Default: nowrap

@mixin flex-wrap($value: nowrap) {
  // No Webkit Box fallback.
  -webkit-flex-wrap: $value;
  -moz-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

//----------------------------------------------------------------------

// Flexbox Flow (shorthand)
// Values: <flex-direction> | <flex-wrap>
// Default: row nowrap

@mixin flex-flow($values: (row nowrap)) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

//----------------------------------------------------------------------

// Flexbox Order
// Default: 0

@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -moz-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

//----------------------------------------------------------------------

// Flexbox Grow
// Default: 0
// http://w3.org/tr/css3-flexbox/#flex-grow-property

@mixin flex-grow($int: 0) {
  -webkit-box-flex: $int;
  -webkit-flex-grow: $int;
  -moz-flex-grow: $int;
  -ms-flex-positive: $int;
  flex-grow: $int;
}

//----------------------------------------------------------------------

// Flexbox Shrink
// Default: 1
// http://w3.org/tr/css3-flexbox/#flex-shrink-property

@mixin flex-shrink($int: 1) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex-negative: $int;
  flex-shrink: $int;
}

//----------------------------------------------------------------------

// Flexbox Basis
// Values: Like "width"
// Default: auto

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  -moz-flex-basis: $value;
  -ms-flex-preferred-size: $value;
  flex-basis: $value;
}

//----------------------------------------------------------------------

// Flexbox "Flex" (shorthand)
// Values: none | <flex-grow> <flex-shrink> || <flex-basis>
// Default: See individual properties (1 1 0).

@mixin flex($fg: 1, $fs: null, $fb: null) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

//----------------------------------------------------------------------

// Flexbox Justify Content
// Values: flex-start | flex-end | center | space-between | space-around
// Default: flex-start

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Items
// Values: flex-start | flex-end | center | baseline | stretch
// Default: stretch

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

//----------------------------------

// Flexbox Align Self
// Values: auto | flex-start | flex-end | center | baseline | stretch
// Default: auto

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

//----------------------------------------------------------------------

// Flexbox Align Content
// Values: flex-start | flex-end | center | space-between | space-around | stretch
// Default: stretch

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

// Transform Origin
@mixin transform-origin($trfm-o) {
  -webkit-transform-origin: $trfm-o;
  transform-origin: $trfm-o;
}

// Transform
@mixin transform($trfm) {
  -webkit-transform: $trfm;
  transform: $trfm;
}

// Triangle
@mixin triangle($dir, $w, $h, $clr) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $dir == top {
    border-width: 0 ceil($w/2) $h ceil($w/2);
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom {
    border-width: $h ceil($w/2) 0 ceil($w/2);
    border-color: $clr transparent transparent transparent;
  } @else if $dir == left {
    border-width: ceil($h/2) $w ceil($h/2) 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == right {
    border-width: ceil($h/2) 0 ceil($h/2) $w;
    border-color: transparent transparent transparent $clr;
  } @else if $dir == top_left {
    border-width: $w $w 0 0;
    border-color: $clr transparent transparent transparent;
  } @else if $dir == top_right {
    border-width: 0 $w $w 0;
    border-color: transparent $clr transparent transparent;
  } @else if $dir == bottom_right {
    border-width: 0 0 $w $w;
    border-color: transparent transparent $clr transparent;
  } @else if $dir == bottom_left {
    border-width: $w 0 0 $w;
    border-color: transparent transparent transparent $clr;
  }
}

// Clearfix
@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: "";
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}

@include keyframes(sprite-animation){
  from{
    background-position: 0 0;
  }
}

// Variables
$fa: 'FontAwesome';

// Usefull Functions

@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function rem($px-size) {
  @if ($debug == 1) {
    @return $px-size;
  } @else {
    @return str-replace(#{($px-size/100)}, px, rem);
  }
}

@function remove($list, $value, $recursive: false) {
  $result: ();

  @for $i from 1 through length($list) {
    @if type-of(nth($list, $i)) == list and $recursive {
      $result: append($result, remove(nth($list, $i), $value, $recursive));
    } @else if nth($list, $i) != $value {
      $result: append($result, nth($list, $i));
    }
  }

  @return $result;
}

@function sort($list) {
  $sortedlist: ();
  @while length($list) > 0 {
    $value: nth($list, 1);
    @each $item in $list {
      @if $item < $value {
        $value: $item;
      }
    }
    $sortedlist: append($sortedlist, $value, 'space');
    $list: remove($list, $value);
  }
  @return $sortedlist;
}

@function average($list) {
  $sum: 0;

  @each $i in $list {
    $sum: $sum + $i;
  }

  @return $sum / length($list);
}

@function headings() {
  @return "h1, h2, h3, h4, h5, h6, .heading-1, .heading-2, .heading-3, .heading-4,.heading-5, .heading-6";
}

@function cols($resolution) {
  $selector: ();
  @each $j in 1, 2, 3, 4, 6, 12 {
    $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n + #{(12/$j)+1})", comma);
  }

  $selector: append($selector, "> .col-#{$resolution}-8.col-#{$resolution}-preffix-2");

  @return $selector;
}

@function cols-full($resolution) {
  $selector: ();
  @for $j from 1 through 12 {
    $selector: append($selector, "> .col-#{$resolution}-#{$j}:nth-child(n)", comma);
  }

  @return $selector;
}

@mixin make-flow-offset($lg-offset, $md-offset: $lg-offset, $sm-offset: $md-offset, $xs-offset: $sm-offset) {
  & > * + * {
    margin-top: $xs-offset;
  }

  html:not(.lt-ie10) & {
    @media (min-width: $screen-xs-min) {
      #{cols-full("xs")} {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-xs-min) {
      #{cols("xs")} {
        margin-top: $xs-offset;
      }
    }

    @media (min-width: $screen-sm-min) {
      #{cols-full("sm")} {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-sm-min) {
      #{cols("sm")} {
        margin-top: $sm-offset;
      }
    }

    @media (min-width: $screen-md-min) {
      #{cols-full("md")} {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-md-min) {
      #{cols("md")} {
        margin-top: $md-offset;
      }
    }

    @media (min-width: $screen-lg-min) {
      #{cols-full("lg")} {
        margin-top: 0;
      }
    }

    @media (min-width: $screen-lg-min) {
      #{cols("lg")} {
        margin-top: $lg-offset;
      }
    }
  }
}

//Visibility
@mixin make-visible( $type: block, $resolution: null){
  @if ($resolution == null) {
    $resolution: ''
  } @else {
    $resolution: str_insert($resolution, '-', str_length($resolution) + 1);
  }

  .visible-#{$resolution}#{$type}{
    display: $type;
  }
}

@mixin make-hidden($resolution: null){
  @if ($resolution == null) {
    $resolution: ''
  } @else {
    $resolution: str_insert($resolution, '-', 0);
  }

  .hidden#{$resolution}{
    display: none;
  }
}

//
// Grid-dedicated responsive offset system
//

@mixin grid-offset($offset) {
  margin-bottom: -$offset;

  &:empty {
    margin-bottom: 0;
  }

  > * {
    margin-bottom: $offset;
  }
}